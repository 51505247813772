import PropTypes from 'prop-types'
import React from 'react'
import imgpi from '../images/pi.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="image icon">
      <img src={imgpi} alt="" />
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Get ∏</h1>
        <p>
        The First Digital Currency You Can Mine On Your Phone{' '}
          <a href="https://minepi.com/tma3791#download">using tma3791 as invite code</a>
          <br />
          Since this is invite only network, please use{' '}
          <a href="https://minepi.com/tma3791">this link</a> to get started.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li> */}
{/*         <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li> */}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
